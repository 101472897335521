<div class="sticky-top-nav">
  <app-topbar *ngIf="isLoggedIn()"></app-topbar>
  <div class="topnav" *ngIf="isLoggedIn() && (status == 'ACTIVE')">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
        <div
          class="collapse navbar-collapse active collapsing"
          id="topnav-menu-content"
        >
          <ul class="navbar-nav justify-content-evenly" #ul>
            <ng-container *ngFor="let item of menuItems">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle d-flex align-items-center arrow-none"
                  *ngIf="!item.subItems"
                  href="javascript: void(0);"
                  id="topnav-components"
                  [routerLink]="item.link"
                  (click)="closeMenu()"
                  disabled
                >
                  <i class="bx {{ item.icon }} me-1 text-primary-400"></i
                  ><span [ngClass]="{'text-secondary-500' : fetchRoute(item.link)}">{{ item.label | translate }}</span>
                  <div class="arrow-down" *ngIf="hasItems(item)"></div>
                </a>

                <a
                  *ngIf="item.subItems"
                  class="nav-link dropdown-toggle arrow-none d-flex align-items-center close"
                  href="javascript: void(0);"
                  id="topnav-components"
                  role="button"
                  (click)="onMenuClick($event, ul)"
                >
                  <i class="bx {{ item.icon }} me-1 text-primary-400"></i>
                  <span [ngClass]="{'text-secondary-500' : fetchRoute(item.link), 'dark-menu-items' : detectMobile}">{{ item.label | translate }}</span>
                  <div class="arrow-down"></div>
                </a>

                <div
                  class="dropdown-menu"
                  [ngClass]="{
                    'dropdown-mega-menu-xl px-2': item.subItems.length > 11
                  }"
                  aria-labelledby="topnav-dashboard"
                  *ngIf="hasItems(item)"
                >
                  <ng-template
                    ngFor
                    let-i="index"
                    let-subitem
                    [ngForOf]="item.subItems"
                  >
                    <a
                      *ngIf="item.subItems.length < 11 && !hasItems(subitem)"
                      class="col dropdown-item side-nav-link-ref"
                      [routerLink]="subitem.link"
                      (click)="closeMenu()"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                    >
                      {{ subitem.label | translate }}
                    </a>
                    <div *ngIf="item.subItems.length > 11">
                      <div *ngIf="i % 3 == 0" class="row">
                        <div class="col-lg-4">
                          <a
                            class="dropdown-item side-nav-link-ref"
                            routerLinkActive="active"
                            [routerLink]="subitem.link"
                            >{{ item.subItems[i].label | translate }}</a
                          >
                        </div>
                        <div class="col-lg-4">
                          <a
                            class="dropdown-item side-nav-link-ref"
                            routerLinkActive="active"
                            [routerLink]="item.subItems[i + 1]?.link"
                            >{{ item.subItems[i + 1]?.label | translate }}</a
                          >
                        </div>
                        <div class="col-lg-4">
                          <a
                            class="dropdown-item side-nav-link-ref"
                            routerLinkActive="active"
                            [routerLink]="item.subItems[i + 2]?.link"
                            >{{ item.subItems[i + 2]?.label | translate }}</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="dropdown" *ngIf="hasItems(subitem)">
                      <a
                        class="dropdown-item dropdown-toggle"
                        href="javascript: void(0);"
                        (click)="onMenuClick($event)"
                        >{{ subitem.label | translate }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <ng-template
                          ngFor
                          let-subSubitem
                          [ngForOf]="subitem.subItems"
                        >
                          <a
                            class="dropdown-item side-nav-link-ref"
                            *ngIf="!hasItems(subSubitem)"
                            href="javascript: void(0);"
                            [routerLink]="subSubitem.link"
                            routerLinkActive="active"
                            >{{ subSubitem.label | translate }}</a
                          >
                          <div class="dropdown" *ngIf="hasItems(subSubitem)">
                            <a
                              class="dropdown-item dropdown-toggle"
                              href="javascript: void(0);"
                              (click)="onMenuClick($event)"
                              >{{ subSubitem.label | translate }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <ng-template
                                ngFor
                                let-subSubSubitem
                                [ngForOf]="subSubitem.subItems"
                              >
                                <a
                                  class="dropdown-item side-nav-link-ref"
                                  href="javascript: void(0);"
                                  [routerLink]="subSubSubitem.link"
                                  routerLinkActive="active"
                                  >{{ subSubSubitem.label | translate }}</a
                                >
                              </ng-template>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div class="topnav" *ngIf="!isLoggedIn() || (status != 'ACTIVE')">
    <div class="container">
      <nav
        class="navbar navbar-light navbar-expand-lg topnav-menu justify-content-between active"
      >
        <div class="d-flex justify-content-between header-responsive">
          <a routerLink="/"
            ><img src="assets/images/auth/logo.png" alt="logo" width="154"
          /></a>
          <div 
              class="pt-2 pe-2 menu-icon"
              (click)="mobileMenuShown = !mobileMenuShown"
            >
              <i style=" font-size: 25px;"
              [style.color]="mobileMenuShown ? 'black' : 'black'"
              class="icon dripicons-align-right"></i>
            </div>
        </div>
        <div *ngIf="mobileMenuShown" class="collapse navbar-collapse flex-grow-0">
          <ul class="navbar-nav">
            <li class="nav-item dropdown nav-buttons-in-menu px-2">
              <a
                class="nav-link dropdown-toggle arrow-none"
                id="topnav-components"
                routerLink="/account/login"
                role="button"
              >
                Sign In
              </a>
            </li>
            <li class="nav-item dropdown nav-buttons-in-menu px-2">
              <a
                class="nav-link dropdown-toggle arrow-none"
                id="topnav-components"
                routerLink="/account/register"
                role="button"
              >
                Join Us
              </a>
            </li>
            <ng-container *ngFor="let item of listLandingPage">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle arrow-none"
                  id="topnav-components"
                  [routerLink]="item.link"
                  role="button"
                >
                  <i class="bx {{ item.icon }} me-2"></i
                  >{{ item.label | translate }}
                  <div class="arrow-down" *ngIf="hasItems(item)"></div>
                </a>
              </li>
            </ng-container>
            <div class="dropdown d-inline-block"style="height: 50px;" ngbDropdown>
              <button style="height: 50px;"
                type="button"
                class="btn header-item"
                id="page-header-user-dropdown"
                ngbDropdownToggle
              >
                <img
                  *ngIf="flagvalue !== undefined"
                  src="{{ flagvalue }}"
                  alt="Header Language"
                  height="16"
                />
                <span class="ms-1" style="color: white !important;">{{ countryName }}</span>
                <img
                  *ngIf="flagvalue === undefined"
                  src="{{ valueset }}"
                  alt="Header Language"
                  height="16"
                />
                <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
              </button>
              <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                <!-- item-->
                <a
                  href="javascript:void(0);"
                  class="dropdown-item notify-item text-black"
                  *ngFor="let item of listLang"
                  (click)="setLanguage(item.text, item.lang, item.flag)"
                  [ngClass]="{ active: cookieValue === item.lang }"
                >
                  <img
                    src="{{ item.flag }}"
                    alt="user-image"
                    class="mx-1"
                    height="12"
                  />
                  <span class="align-middle">{{ item.text }}</span>
                </a>
                <!-- item-->
              </div>
            </div>
            <!-- <li class="has-sub">
              <a><img
                *ngIf="flagvalue !== undefined"
                src="{{ flagvalue }}"
                alt="Header Language"
                height="16"
              />
              <span class="ms-1">{{ countryName }}</span>
              <img
                *ngIf="flagvalue === undefined"
                src="{{ valueset }}"
                alt="Header Language"
                height="16"
              />
              <span *ngIf="flagvalue === undefined" class="ms-1">English</span></a>
              <ul>
                <li
                  *ngFor="let item of listLang"
                >
                  <a
                  href="javascript:void(0);"
                  class="dropdown-item notify-item text-black"
                  (click)="setLanguage(item.text, item.lang, item.flag)"
                  [ngClass]="{ active: cookieValue === item.lang }"
                >
                  <img
                    src="{{ item.flag }}"
                    alt="user-image"
                    class="mx-1"
                    height="12"
                  />
                  <span class="align-middle">{{ item.text }}</span>
                </a>
                </li>
              </ul>
            </li> -->
          </ul>
        </div>
        <div *ngIf="!mobileMenuShown" class="collapse flex-grow-0 spare-menu-items">
          <ul class="navbar-nav">
            <li class="nav-item dropdown nav-buttons-in-menu px-2">
              <a
                class="nav-link dropdown-toggle arrow-none"
                id="topnav-components"
                routerLink="/account/login"
                role="button"
              >
                Sign In
              </a>
            </li>
            <li class="nav-item dropdown nav-buttons-in-menu px-2">
              <a
                class="nav-link dropdown-toggle arrow-none"
                id="topnav-components"
                routerLink="/account/register"
                role="button"
              >
                Join Us
              </a>
            </li>
            <ng-container *ngFor="let item of listLandingPage">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle arrow-none"
                  id="topnav-components"
                  [routerLink]="item.link"
                  role="button"
                >
                  <i class="bx {{ item.icon }} me-2"></i
                  >{{ item.label | translate }}
                  <div class="arrow-down" *ngIf="hasItems(item)"></div>
                </a>
              </li>
            </ng-container>
            <div class="dropdown d-inline-block"style="height: 50px;" ngbDropdown>
              <button style="height: 50px;"
                type="button"
                class="btn header-item"
                id="page-header-user-dropdown"
                ngbDropdownToggle
              >
                <img
                  *ngIf="flagvalue !== undefined"
                  src="{{ flagvalue }}"
                  alt="Header Language"
                  height="16"
                />
                <span class="ms-1" style="color: white !important;">{{ countryName }}</span>
                <img
                  *ngIf="flagvalue === undefined"
                  src="{{ valueset }}"
                  alt="Header Language"
                  height="16"
                />
                <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
              </button>
              <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                <!-- item-->
                <a
                  href="javascript:void(0);"
                  class="dropdown-item notify-item text-black"
                  *ngFor="let item of listLang"
                  (click)="setLanguage(item.text, item.lang, item.flag)"
                  [ngClass]="{ active: cookieValue === item.lang }"
                >
                  <img
                    src="{{ item.flag }}"
                    alt="user-image"
                    class="mx-1"
                    height="12"
                  />
                  <span class="align-middle">{{ item.text }}</span>
                </a>
                <!-- item-->
              </div>
            </div>
            <!-- <li class="has-sub">
              <a><img
                *ngIf="flagvalue !== undefined"
                src="{{ flagvalue }}"
                alt="Header Language"
                height="16"
              />
              <span class="ms-1">{{ countryName }}</span>
              <img
                *ngIf="flagvalue === undefined"
                src="{{ valueset }}"
                alt="Header Language"
                height="16"
              />
              <span *ngIf="flagvalue === undefined" class="ms-1">English</span></a>
              <ul>
                <li
                  *ngFor="let item of listLang"
                >
                  <a
                  href="javascript:void(0);"
                  class="dropdown-item notify-item text-black"
                  (click)="setLanguage(item.text, item.lang, item.flag)"
                  [ngClass]="{ active: cookieValue === item.lang }"
                >
                  <img
                    src="{{ item.flag }}"
                    alt="user-image"
                    class="mx-1"
                    height="12"
                  />
                  <span class="align-middle">{{ item.text }}</span>
                </a>
                </li>
              </ul>
            </li> -->
          </ul>
        </div>
        <div class="d-flex gap-3 align-items-center nav-buttons">
          <a
          class="btn bg-secondary-500 fs-5 text-white fw-semibold rounded-pill px-4"
          routerLink="/account/login"
          >Sign In</a
          >
          <a class="btn bg-secondary-500 fs-5 text-white fw-semibold rounded-pill px-4"
            routerLink="/account/register"
            >Join Us</a
          >
        </div>
      </nav>
    </div>
  </div>
</div>
